import styled from 'styled-components'

export const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5rem;
`

export const Dialog = styled.div`
	padding: 1rem;
	display: grid;
	grid-template-columns: 1fr;
  	grid-template-rows: auto 1fr;
	row-gap: 1.25rem;
	position: relative;
	overflow: hidden;
  	max-height: 50rem;
  	color: #545454;

	@media (min-width: 768px) {
		padding: 1.5rem;
	}
`

Dialog.Header = styled.div`
  text-align: start;
  	font-size: 1.25rem;
`
Dialog.Caption = styled.div`
  text-align: start;
  margin: 12px 0px;
  font-size: 14px;
  color: #909090;
`

Dialog.Content = styled.div`
  overflow: hidden;
  overflow-y: auto;    
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  padding: 0px 15px;
  margin: 0 -15px;
`

Dialog.CloseButton = styled.button`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
	position: absolute;
	top: 0.75rem;
  right: 0.75rem;
	cursor: pointer;

	.custom-icon svg {
		height: 1.25rem;
    width: 1.25rem;
    opacity: .6;

		path {
			fill: #000000;
		}
	}
`

Dialog.Title = styled.span`
	text-align: center;
	font-size: 1.25rem;
	font-weight: 700;
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
`

export const SelectButtonContainer = styled.div`
	display: flex;
	gap: 15px;		
	padding: 0 0 15px 0;
	overflow-x: hidden;
    overflow-y: hidden;

	&:hover {
		overflow-x: auto;
	}

	&::-webkit-scrollbar-track {
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar {
		width: 3px;
		height: 2px;
		background-color: #F5F5F5;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
		border: 1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: none;
		box-shadow:  none;
	}

`

SelectButtonContainer.Title = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
	margin-bottom: 5px;
`

export const SelectButton = styled.button`
	cursor: pointer;
	background: #fff;
	padding: 5px 20px;	
	border-radius: 8px;
	border: 1px solid #ccc;
	color: #ccc;

	&.active {
		border: 1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
		color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
	}
`

SelectButton.Title = styled.div`
	font-size: 1rem;
	font-weight: bold;
`

SelectButton.Date = styled.div`
	font-size: 1.25rem;
	font-weight: bold;
`

export const ButtonAction = styled.button`
	width: 100%;
	cursor: pointer;
	background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
	border: none;
	padding: 15px;
	color: #fff;
	border-radius: 4px;
	font-size: 0.875rem;	

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
	fetchDeliveryDates as fetchDeliveryDatesAction,
	setSelectedDeliveryDate as setSelectedDeliveryDateAction,	
} from '../../../../redux/actions/main';
import { pipe, GetContent } from '../../../../domain/helpers';

import {
	CustomIcon,
	CustomLoading,	
} from '../../../components';

import {
	ButtonAction,
	Dialog,
	LoadingContainer,
	SelectButton,
	SelectButtonContainer,
} from './styles';

const Context = React.createContext({});

function DeliveryDateSelecionComponent(props) {
	const {
		fetchDeliveryDates,
		loading,
		close,
		avaliableDates,
		setSelectedDeliveryDate,
		selectedDeliveryDate,
	} = props;

	

	const [dateSelected, setDateSelected] = useState(null);
	const [hourSelected, setHourSelected] = useState(null);

	const isLoading = loading && loading.includes('deliveryDates');	

	useEffect(() => {
		async function getDeliveryDates() {
			await fetchDeliveryDates();
		}

		getDeliveryDates();
	}, [fetchDeliveryDates]);

	useEffect(() => {
		if(selectedDeliveryDate){
			const selectedDate = avaliableDates?.dates?.find((item) => item.date == selectedDeliveryDate.date)
			setDateSelected(avaliableDates?.dates?.indexOf(selectedDate))

			const selectedHour = avaliableDates?.dates[dateSelected]?.hours?.find((item) => item.hour == selectedDeliveryDate.hour.time)
			setHourSelected(avaliableDates?.dates[dateSelected]?.hours?.indexOf(selectedHour))
		}
	},[avaliableDates])

	if (isLoading) {
		return (
			<LoadingContainer>
				<CustomLoading
					type={'spin'}
					id='default-loading'
					height={40}
					width={40}
				/>
			</LoadingContainer>
		);
	}

	const getWeek = (date) => {
		// format segunda , terça, quarta, quinta, sexta, sabado, domingo in pt-br
		return new Date(date).toLocaleDateString('pt-BR', {
			weekday: 'long',
		}).split('-')[0];				
	}

	const getDate = (date) => {		
		return new Date(date).toLocaleDateString('pt-BR', {
			day: '2-digit',
			month: '2-digit',
		});
	}
		

	return (
		<Dialog>
			<Dialog.Header>
				<Dialog.Title>Agendamento de entrega</Dialog.Title>
				<Dialog.Caption>Escolha o dia e o horário que deseja receber seu pedido ( podendo variar em até {avaliableDates?.tolerance} min).</Dialog.Caption>
				<Dialog.CloseButton className='close-button' onClick={() => close()}>
					<CustomIcon name='Times' />
				</Dialog.CloseButton>
			</Dialog.Header>
			<Dialog.Content>

			<div>
			<SelectButtonContainer.Title>Data:</SelectButtonContainer.Title>
			<SelectButtonContainer>
			{avaliableDates?.dates?.map((item, index) => (
				<SelectButton key={index} onClick={() => {
					setDateSelected(index)
					setHourSelected(null)
				}} className={index == dateSelected ? "active" : ""}>
					<SelectButton.Title>{getWeek(item.date)}</SelectButton.Title>
					<SelectButton.Date>{getDate(item.date)}</SelectButton.Date>
			    </SelectButton>
			))}			
			</SelectButtonContainer>
			</div>

			{dateSelected !== null && (
			<div>
				<SelectButtonContainer.Title>Horário:</SelectButtonContainer.Title>
				<SelectButtonContainer>
					{avaliableDates?.dates[dateSelected].hours?.map((item, index) => (
					<SelectButton key={index} onClick={() => setHourSelected(index)} className={index == hourSelected ? "active" : ""} style={{
						padding: '15px 20px'
					}}>
						<SelectButton.Date>{item.hour}</SelectButton.Date>
					</SelectButton>
				))}		
				</SelectButtonContainer>
			</div>)
			}

			<ButtonAction disabled={dateSelected == null || hourSelected == null} onClick={() => {
				if (dateSelected !== null && hourSelected !== null) {
					setSelectedDeliveryDate({
						date: avaliableDates?.dates[dateSelected].date,
						hour: {
							time: avaliableDates?.dates[dateSelected].hours[hourSelected].hour,
							id: avaliableDates?.dates[dateSelected].hours[hourSelected].id,
						}
					});
					close();
				}
			}}>				
				Agendar
			</ButtonAction>
			</Dialog.Content>
		</Dialog>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.loading.loading || [],
		avaliableDates: state.main.avaliableDates || [],
	};
};

const GetConnection = connect(mapStateToProps, {
	fetchDeliveryDates: fetchDeliveryDatesAction,
	setSelectedDeliveryDate: setSelectedDeliveryDateAction,
});

export const DeliveryDateSelecion = React.memo(
	pipe(
		GetConnection,
		GetContent({ context: Context, id: 'delivery-date-selection' })
	)(DeliveryDateSelecionComponent)
);
